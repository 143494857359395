<template>
  <div class="form">
    <div class="info-box">
      <!-- 名称 -->
      <div class="key-value">
        <div class="key">
          {{ proposal.module + proposal.name }}
        </div>
        <VanField
          translate="no"
          class="input-box"
          input-align="center"
          v-model="params1.name"
          :placeholder="common.pleaseInput"
          maxlength="40"
        ></VanField>
      </div>
      <!-- 简介 中-->
      <div class="key-value">
        <div class="key">
          {{ proposal.module + proposal.content }}
        </div>
        <VanField
          translate="no"
          type="textarea"
          cols="10"
          rows="8"
          maxlength="1000"
          class="textarea-box"
          v-model="params1.content"
          show-word-limit
          :placeholder="common.pleaseInput"
        ></VanField>
      </div>
      <!-- importantTypeg -->
      <div class="key-value">
        <div class="key">
          {{ proposal.module + proposal.importantType }}
        </div>
        <div class="btn-groups">
          <VanButton
            v-for="(item, index) in typeList"
            :key="index"
            class="btn"
            :class="{ active: params2.type === typeList[index] }"
            @click="handleChange1(typeList[index])"
          >
            {{ proposal.list1[index] }}
          </VanButton>
        </div>
      </div>
      <!-- tag -->
      <div class="key-value">
        <div class="key">
          {{ proposal.module + proposal.tag }}
        </div>
        <div class="btn-groups">
          <VanButton
            v-for="(item, index) in typeList"
            :key="index"
            class="btn"
            :class="{ active: params2.tag === typeList[index] }"
            @click="handleChange2(typeList[index])"
          >
            {{ proposal.list2[index] }}
          </VanButton>
        </div>
      </div>
      <!-- 投票激励 -->
      <div class="key-value">
        <div class="key">
          {{ proposal.module + proposal.voteReward }}
          <span class="fu-title"
            >{{ proposal.voteTip }}({{ tokenInfo.name }})</span
          >
        </div>
        <div class="btn-groups not-justify">
          <VanButton
            class="btn"
            :disabled="isEdit"
            :class="{ active: isReward }"
            @click="handleChange3(true)"
          >
            {{ common.open }}
          </VanButton>
          <VanButton
            class="btn"
            :class="{ active: !isReward }"
            :disabled="isEdit"
            @click="handleChange3(false)"
          >
            {{ common.close }}
          </VanButton>
        </div>
      </div>
      <!-- 激励总额 -->
      <div class="key-value" v-if="isReward">
        <div class="key">
          {{ proposal.rewardTotal }}
        </div>
        <VanField
          translate="no"
          class="input-box"
          input-align="center"
          v-model="params2.reward"
          :placeholder="common.pleaseInput"
          :disabled="isEdit"
          maxlength="40"
        ></VanField>
        <div class="margin-height fu-title">{{ proposal.rewardTip }}</div>
      </div>
      <!-- 投票时间 -->
      <div class="key-value">
        <div class="key">
          {{ proposal.voteTime }}
        </div>
        <div class="time-wrapper input-box _flex">
          <i class="iconfont shijian time-icon"></i>
          <!-- <svg class="time-icon" aria-hidden="true">
            <use xlink:href="#shijian"></use>
          </svg> -->
          <VanButton
            class="time-box"
            :class="{ activeColor: startDate }"
            :disabled="isEdit"
            @click="
              () => {
                startTimeShow = true
              }
            "
          >
            {{ startDate ? startDate : $t('common.startTime') }}
          </VanButton>
          <div class="line" />
          <VanButton
            class="time-box"
            :class="{ activeColor: endDate }"
            :disabled="!startDate || isEdit"
            @click="
              () => {
                endTimeShow = true
              }
            "
          >
            {{ endDate ? endDate : $t('common.endTime') }}
          </VanButton>
        </div>
        <div class="_flex" v-if="false">
          <VanField
            translate="no"
            class="input-box"
            input-align="center"
            v-model="params2.startTime"
            :placeholder="common.pleaseInput"
            :disabled="isEdit"
            style="width: 45%; display: inline-block"
          ></VanField>
          <span>———</span>
          <VanField
            translate="no"
            class="input-box"
            input-align="center"
            v-model="params2.endTime"
            :placeholder="common.pleaseInput"
            :disabled="isEdit"
            style="width: 45%; display: inline-block"
          ></VanField>
        </div>
      </div>
      <!-- 投票制度 -->
      <div class="key-value">
        <div class="key">
          {{ proposal.votingSystem }}
        </div>
        <div class="btn-groups not-justify">
          <VanButton
            class="btn"
            :class="{ active: !mutilOption }"
            @click="handleChange4(false)"
          >
            {{ common.single }}
          </VanButton>
          <VanButton
            class="btn"
            :class="{ active: mutilOption }"
            @click="handleChange4(true)"
          >
            {{ common.multi }}
          </VanButton>
        </div>
      </div>
      <div class="key-value" v-for="(item, index) in options" :key="index">
        <div class="key">
          <!-- 选项index  -->
          <span>{{ proposal.option }}{{ index + 1 }}</span>
          <!-- 删除 -->
          <span class="del-btn" @click="delPlan(index)" v-if="index >= 2">{{
            common.delete
          }}</span>
        </div>
        <VanField
          translate="no"
          class="input-box"
          input-align="center"
          maxlength="100"
          v-model="options[index]"
          :placeholder="common.pleaseInput"
        ></VanField>
      </div>
      <!-- 增加投票选项 -->
      <VanButton
        class="add-btn"
        @click="handleAddPlan"
        :disabled="options.length >= 10"
        >{{ proposal.addOptions
        }}<span class="size-12">{{ proposal.maxOptions }}</span></VanButton
      >
      <!-- 提案付钱提示 -->
    </div>
    <div class="tips" v-if="!isCouncil">
      {{ $t('proposal.needStake') }}
      <span translate="no"
        >（{{ $gbUtils.formatBalance(tokenInfo.totalSupply * point) }}
        {{ tokenInfo.name }}）</span
      >{{ $t('proposal.canSubmit') }}
    </div>
    <!-- 设置奖励提示 -->
    <div class="tips" v-if="isReward">
      {{ $t('proposal.needPay') }}
      <span translate="no"> {{ params2.reward }} {{ tokenInfo.name }}</span
      >{{ $t('common.usedFor') + $t('proposal.voteReward') }}
    </div>
    <div class="bottom-class">
      <div class="_flex">
        <VanButton
          class="create-btn"
          :loading="loadingApprove"
          :disabled="tokenAuth"
          @click="approveToken"
        >
          {{ common.Apporve }}
        </VanButton>
        <!-- 质押按钮 -->
        <VanButton
          class="create-btn"
          :loading="loadingAdd"
          :disabled="
            !tokenAuth ||
            !params1.name ||
            !params1.content ||
            (isReward && params2.reward <= 0)
          "
          @click="handleAdd"
        >
          {{ isEdit ? $t('common.confrim') : $t('proposal.satkeAndSubmit') }}
        </VanButton>
      </div>
      <div class="tips">{{ $t('proposal.endNotice') }}</div>
    </div>
    <van-popup
      v-model="startTimeShow"
      round
      position="center"
      style="min-width: 320px; width: 95%"
    >
      <!-- style="max-width: 400px; left: 50%; transform: translateX(-50%)" -->
      <van-datetime-picker
        type="datetime"
        :confirm-button-text="$t('common.confirm')"
        :cancel-button-text="$t('common.cancel')"
        :title="$t('proposal.startTime')"
        :min-date="new Date()"
        :max-date="startMaxDate"
        item-height="40px"
        @confirm="handleStartTime"
        @cancel="
          () => {
            startTimeShow = false
          }
        "
      />
    </van-popup>
    <van-popup
      v-model="endTimeShow"
      round
      position="center"
      style="min-width: 370px"
    >
      <van-datetime-picker
        :min-date="endMinDate"
        :max-date="endMaxDate"
        @confirm="handleEndTime"
        type="datetime"
        :title="$t('proposal.endTime')"
        :confirm-button-text="$t('common.confirm')"
        :cancel-button-text="$t('common.cancel')"
        item-height="40px"
        @cancel="
          () => {
            endTimeShow = false
          }
        "
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  name: '',
  // isEdit 编辑
  props: [
    'isEdit',
    'tokenInfo',
    'isCouncil',
    'tokenAuth',
    'loadingApprove',
    'loadingAdd',
    'dateSource'
  ],
  data () {
    return {
      point: 0.00000005,
      typeList: [10, 20, 30],
      params1: {
        name: '',
        content: ''
      },
      params2: {
        type: 10,
        tag: 10,
        reward: 0,
        startTime: '',
        endTime: ''
      },
      startTimeShow: false, // 开始时间选择
      endTimeShow: false, // 结束时间选择
      startDate: null, // 开始时间
      endDate: null, // 结束时间
      startMaxDate: null, // 最小开始时间
      endMinDate: null, // 最小结束时间
      endMaxDate: null, // 最大结束时间
      options: ['', ''],
      isReward: false,
      // isCouncil: false, // 是否是理事
      // loadingAdd: false,
      mutilOption: false // 多选
    }
  },
  computed: {
    proposal () {
      return this.$t('proposal')
    },
    common () {
      return this.$t('common')
    }
  },
  methods: {
    init () {
      this.startMaxDate = this.$moment(new Date()).add('days', 7)._d
      this.formatData()
    },
    formatData () {
      // var info = sessionStorage.getItem('proposalDetail') || ''
      if (!this.dateSource) return
      if (!this.dateSource.token) return
      const info = this.dateSource
      this.params1.name = info.name_desc[0]
      this.params1.content = info.name_desc[1]
      this.params2 = {
        type: parseInt(info.uintParams[0]),
        tag: parseInt(info.uintParams[1]),
        reward: info.uintParams[2],
        startTime: info.uintParams[3],
        endTime: info.uintParams[4]
      }
      this.mutilOption = info.mutilOption
      this.options = info.options || []
      this.startDate = this.$moment(info.uintParams[3] * 1000).format(
        'YYYY-MM-DD HH:mm'
      )
      this.endDate = this.$moment(info.uintParams[4] * 1000).format(
        'YYYY-MM-DD HH:mm'
      )
    },
    handleAddPlan () {
      this.options.push('')
    },
    delPlan (index) {
      this.options.splice(index, 1)
    },
    handleChange1 (value) {
      this.params2.type = value
    },
    handleChange2 (value) {
      this.params2.tag = value
    },
    handleChange3 (flag) {
      this.isReward = flag
    },
    handleChange4 (flag) {
      this.mutilOption = flag
    },
    approveToken () {
      this.$emit('approveToken')
    },
    handleAdd () {
      const planList = this.options.filter((item) => {
        return item !== ''
      })
      if (!this.params2.startTime) {
        return this.$toast(this.common.pleaseInput + this.proposal.startTime)
      }
      if (!this.params2.endTime) {
        return this.$toast(this.common.pleaseInput + this.proposal.endTime)
      }
      if (this.isReward && this.params2.reward <= 0) {
        return this.$toast(this.common.pleaseInput + this.proposal.rewardTotal)
      }
      if (planList.length <= 1) {
        return this.$toast(this.common.pleaseInput + this.proposal.option)
      }
      if (!this.isReward) this.params2.reward = 0
      const params1 = Object.values(this.params1)
      const params2 = Object.values(this.params2)
      params2[2] = this.$web3.toWei(params2[2])
      this.$emit('addProposal', params1, params2, this.mutilOption, planList)
    },
    handleStartTime (val) {
      this.startDate = this.$moment(val).format('YYYY-MM-DD HH:mm')
      this.params2.startTime = this.dateToMs(val)
      this.endMinDate = this.$moment(val).add('days', 0)._d
      this.endMaxDate = this.$moment(val).add('days', 30)._d
      if (this.params2.endTime) {
        this.endDate = this.$moment(val).format('YYYY-MM-DD HH:mm')
        this.params2.endTime = this.dateToMs(val)
      }
      this.startTimeShow = false
    },
    handleEndTime (val) {
      this.params2.endTime = this.dateToMs(val)
      this.endDate = this.$moment(val).format('YYYY-MM-DD HH:mm')
      this.endTimeShow = false
    },
    dateToMs (date) {
      const result = new Date(date).getTime() / 1000
      return result
    }
  },
  watch: {
    startDate (val) { },
    dateSource: {
      handler (val) {
        this.formatData()
      },
      deep: true
    }
  },
  mounted () {
    this.init()
  }
}
</script>

<style scoped lang="scss">
.form {
  .info-box {
    .key-value {
      margin-top: 30px;
    }
    .logo-box {
      width: 94px;
      height: 94px;
      background: $base-color;
      box-shadow: $shadow;
      border-radius: 8px;
      display: inherit;
      margin: auto;
      .upload-icon {
        font-size: 38px;
        font-weight: bold;
        width: 94px;
        height: 94px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $color-gray2;
      }
    }
    .textarea-box {
      resize: none;
      padding-left: 10px;
      padding-top: 10px;
      width: 100%;
      border-radius: 8px;
      ::-webkit-input-placeholder {
        color: $color-gray2;
        font-size: $font-12;
      }
    }
    .input-box {
      &.input-address {
        height: 22px !important;
        line-height: 22px !important;
        background: $color-green !important;
        font-size: $font-12;
      }
    }
    /deep/ .van-field__value {
      width: 100%;
    }
  }
  .key {
    font-size: $font-14;
    color: $main-color1;
    margin-bottom: 10px;
    .del-btn {
      float: right;
    }
  }
  .fu-title {
    font-size: $font-12;
    color: $color-orange;
  }
  .create-btn {
    // display: inline-block;
    min-width: 167px;
    height: 39px;
    background: $blue-bg;
    border-radius: 20px;
    color: $base-color;
    font-size: $font-16;
    font-weight: bold;
    margin: 35px auto 18px auto;
  }
  .add-btn {
    height: 39px;
    background: $blue-bg;
    border-radius: 8px;
    margin: 25px 0;
    color: $base-color;
  }
  .bottom-class {
    text-align: center;
  }
  .size-12 {
    font-size: $font-12;
  }
  .margin-height {
    margin-top: 15px;
  }
  .btn-groups {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 30px 0;
    &.not-justify {
      justify-content: flex-start;
    }
    .btn {
      width: 103px;
      height: 33px;
      background: $base-bg;
      border-radius: 8px;
      color: $btn-blue1;
      margin-right: 15px;
      position: relative;
      font-size: 12px;

      ::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: -1;
        margin: -1px;
        border-radius: 8px; /*important*/
        background: linear-gradient(to right, #0078ff, #7c40b7);
      }
      &.active {
        background: $blue-bg;
        color: $base-color;
        font-weight: bold;
      }
    }
  }
  .time-box {
    width: 45%;
    border-radius: 8px;
    height: 40px;
    line-height: 40px;
    color: $main-color1;
    text-align: center;
    padding: 0;
    background: unset;
    &.activeColor {
      color: $color-gray3;
    }
  }
  .tips {
    font-size: 12px;
    color: $color-orange;
  }
  ._flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .time-wrapper {
    .time-icon {
      width: 20px;
      // height: 20px;
      margin-left: 15px;
      color: $color-gray;
      font-size: 22px;
    }
    .line {
      width: 32px;
      height: 1px;
      border: 1px solid $color-gray;
    }
  }
}
</style>
<style lang="scss">
.form {
  .van-picker__toolbar {
    border-bottom: 1px solid #888888;
  }
  .van-picker__title {
    color: $main-color1;
    font-weight: bold;
  }
  .van-picker__cancel {
    color: $main-color1;
  }
  .van-picker__confirm {
    color: $main-color1;
  }
  .van-picker,
  .van-datetime-picker {
    background: #3e3e53;
  }
  .van-picker__mask {
    background: unset;
  }
  .van-picker-column__item {
    color: $main-color1;
  }
  .van-picker-column {
    position: relative;
    z-index: 1;
    &:nth-child(4) {
      border-left: 1px solid #888888;
    }
  }
  .van-picker__frame {
    border-radius: 8px;
    background: $blue-bg;
    z-index: 0;
    // background: linear-gradient(90deg, rgba($color: #0078FF, $alpha: 0.5) 0%, rgba($color: #7C40B7, $alpha: 0.5) 100%);
    &::after {
      border: none;
    }
  }
}
</style>
